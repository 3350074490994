// import Swiper JS
import Swiper from 'swiper/bundle';

var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

(function ($) {
    "use strict";

    // preloader
    $(window).on('load', function () {
        $('#loading-center').delay(350).fadeOut('slow');
        $('#loading').delay(350).fadeOut('slow');
        $('body').delay(350).css({
            'overflow': 'visible'
        });
    })



    // sticky header
    var wind = $(window);
    var sticky = $('#sticky-header');
    wind.on('scroll', function () {
        var scroll = wind.scrollTop();
        if (scroll < 100) {
            sticky.removeClass('sticky-header');
        } else {
            sticky.addClass('sticky-header');
        }
    });

    // mobile menu start
    $('#mobile-menu').meanmenu({
        meanMenuContainer: '.mobile-menu',
        meanScreenWidth: "991"
    });

    $('.side-toggle').on('click', function () {
        $('.side-mobile-menu').addClass('info-open');
        $('.offcanvas-overlay').addClass('overlay-open');
    })

    $('.side-info-close,.offcanvas-overlay').on('click', function () {
        $('.side-mobile-menu').removeClass('info-open');
        $('.offcanvas-overlay').removeClass('overlay-open');
    })


    // SLIDER
    var menu = [];
    jQuery('.swiper-slide').each(function (index) {
        menu.push(jQuery(this).find('.slide-inner').attr("data-text"));
    });
    var interleaveOffset = 0.5;
    var swiperOptions = {
        loop: true,
        direction: 'horizontal',
        speed: 3500,
        parallax: true,
        slidesPerView: 1,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        //watchSlidesProgress: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    };

    var swiper = new Swiper(".swiper-container", swiperOptions);

    //data background
    $("[data-background]").each(function () {
        $(this).css("background-image", "url(" + $(this).attr("data-background") + ") ");
    })

    // brand-active
    $('.brand-active').owlCarousel({
        loop: true,
        margin: 30,
        items: 1,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            767: {
                items: 3
            },
            992: {
                items: 4
            },
            1200: {
                items: 5
            }
        },
        nav: true,
        navText: [
            '<i class="fa fa-angle-left" aria-hidden="true"></i>',
            '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        ],
    })

    /* magnificPopup img view */
    $('.popup-image').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

})(jQuery);