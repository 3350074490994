import SignaturePad from "signature_pad";

var baseUrl = $('meta[name="base-url"]').attr("content");
$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});

var canvaslist = [];
(function() {
    const wrapperSignature = document.getElementsByClassName(
        "signature-pad"
    );

    Array.prototype.forEach.call(wrapperSignature, wrapper => {
        let canvas = wrapper.querySelector("canvas");

        // check if canvas exist
        if (!canvas) {
            return false;
        }

        canvaslist.push(canvas);
        let signaturePad = new SignaturePad(canvas);
        let btnClear = wrapper.querySelector("button.clear-signature-pad");
        let btnSave = wrapper.querySelector("button.save-signature-pad");
        let img = wrapper.querySelector("img.show-signature");
        let input = wrapper.querySelector("input.signature-input");
        let error = wrapper.querySelector(".signature-error");

        btnClear?.addEventListener("click", () => {
            signaturePad.clear();
            img.src = "";
            signaturePad.on();
            btnSave.disabled = false;

            show(canvas);
            hide(img);

            resizeCanvas(canvas);
        });

        btnSave?.addEventListener("click", () => {
            btnSave.disabled = true;
            hide(error);
            let form_data = new FormData();
            if (!signaturePad.isEmpty()) {
                form_data.append(
                    "signature",
                    signaturePad.toDataURL("image/png")
                );
            } else {
                form_data.append("file", input.files[0]);
            }
            form_data.append("position", canvas.getAttribute("position"));
            form_data.append("inscription_id", $('#inscription_id').val());

            $.ajax({
                url: baseUrl + "/ca/inscription/save-signature",
                method: "POST",
                data: form_data,
                processData: false,
                contentType: false,
                success: function(data) {
                    signaturePad.off(); 
                    update(
                        wrapper.dataset.signature,
                        data.url + "?t=" + new Date().getTime()
                    );
                    let position = canvas.getAttribute("position");
                    $('#'+position).val(data.url);
                },
                error: function() {
                    btnSave.disabled = false;
                    show(error);
                }
            });
        });

        input.onchange = evt => {
            signaturePad.clear();
            btnSave.disabled = false;

            const [file] = input.files;
            if (file) {
                img.src = URL.createObjectURL(file);
                show(img);
                hide(canvas);
            }
        };

        if (img.getAttribute("src")) {
            show(img);
            hide(canvas);
            btnSave.disabled = true;
        }
    });

    window.addEventListener("resize", resizeCanvass);
    resizeCanvass();
})();

function resizeCanvass() {
    canvaslist.forEach(canvas => {
        resizeCanvas(canvas);
    });
}

function resizeCanvas(canvas) {
    if (canvas && canvas.style.display != "none") {
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio - 1;
        canvas.getContext("2d").scale(ratio, ratio);
    }
}

function show(el) {
    el.style.display = "";
}

function hide(el) {
    el.style.display = "none";
}

function update(data, src) {
    let wrapperSignature = document.querySelectorAll(
        '[data-signature="' + data + '"]'
    );

    Array.prototype.forEach.call(wrapperSignature, wrapper => {
        let canvas = wrapper.querySelector("canvas");
        let img = wrapper.querySelector("img.show-signature");

        if (src && img) {
            img.src = src;

            show(img);
            hide(canvas);
        }
    });
}