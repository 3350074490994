require("./bootstrap");

/**
 * jQuery
 */
/*  import jQuery from 'jquery';
 window.$ = window.jQuery = jQuery; */

/*  import 'bootstrap'; */
//import 'owl.carousel';
/*  import 'jquery-ui';
 import 'isotope-layout';
 import 'odometer';
 import 'uikit';
 import 'swiper/css/bundle';
 import 'imagesloaded';
 import 'magnific-popup'; */

import "owl.carousel";
import "magnific-popup";

import "./public/jquery.meanmenu";
import "./public/touchspin";
import "./public/jquery.appear";
import "./public/backToTop";
import "./public/signature_pad";
import "./public/main";
import * as CookieConsent from "vanilla-cookieconsent";

window.CookieConsent = CookieConsent;
